import React from "react";
import RecipeList from "../components/RecipeList";

function Recipe() {
  return (
    <div>
      <RecipeList />
    </div>
  );
}

export default Recipe;
